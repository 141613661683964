import React, { Component } from "react"
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { graphql } from 'gatsby'
import Blockquote from 'components/blockquote';
import DatePicker from 'components/form/datepicker/'
import Layout from "templates/basic/"
import SEO from 'components/seo'
import NumberInput from 'components/form/number/'
import parse from 'html-react-parser';
import TextInput from 'components/form/textinput/';
import RadioButton from 'components/form/radiobutton/'
import ReCAPTCHA from 'react-google-recaptcha';
import style from './assistanceRequest.module.scss';
import Intro from 'components/intro';

export default class AssistanceRequest extends Component {
	constructor(props) {
		super(props);
		this.page = null;
		this.recaptchaRef = React.createRef();
		this.recaptchaComplete = this.recaptchaComplete.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.timerID = null;
		if (props.data.allContentPage && props.data.allContentPage.edges.length) this.page = props.data.allContentPage.edges.find(p => p.node.uri === '/shine-on/');
		if (this.page) this.page = this.page.node;

		this.formSchema = Yup.object().shape({
			yourName: Yup.string()
				.required('Please enter your name.'),
			employeeName: Yup.string()
				.required('Please enter the employee&rsquo;s name.'),
			email: Yup.string()
				.email('Please enter a valid email address.')
				.required('Please enter your email address.'),
		});
		this.state = {
			recaptcha: '',
			recaptchaError: false,
			success: false,
		}
		this.initialValues = {
			yourName: '',
			employeeName: '',
			email: '',
			message: '',
			employeeYears: '',
			employeeArea: '',
			employeeArea2: '',
			employeeStanding: '',
			employeeValues: '',
			employeeMedical: '',
			todaysDate: '',
			requestedDate: '',
			otherRequest: '',
			monetaryRequest: '',
			note: false,
			dinner: false,
			flowers: false,
			monetary: false,
			otherCheck: false,
			blanket: false,
			ppe: false,
			env: 'ASSISTANCE_FORM',
			mailto: 'email1',
		}
	}

	recaptchaComplete(value) {
		this.setState({
			recaptcha: value,
			recaptchaError: false,
		});
	}

	componentWillUnmount() {
		if (this.timerID !== null) clearTimeout(this.timerID);
		this.timerID = null;
	}

	async submitForm(values, { setSubmitting, resetForm }) {
		for (const field in values) {
			if (Object.hasOwnProperty.call(values, field)) {
				const value = values[field];
				if (JSON.stringify(value) === "true") values[field] = "Yes"
				if (JSON.stringify(value) === "false") values[field] = "No"
				if (value === "yesExemplary") values[field] = "Yes, in an exemplary way"
				if (value === "yes") values[field] = "Yes"
				if (value === "no") values[field] = "No"
				if (value === "") values[field] = "No"
				if (Object.prototype.toString.call(value) === '[object Date]') {
					// Create a date object from the timestamp
					var date = new Date(value);

					// Create a list of names for the months
					var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

					// return a formatted date
					values[field] = months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
				}
			}
		}

		if (!this.state.recaptcha) {
			setSubmitting(false);
			this.setState({
				recaptchaError: true,
			});
			return;
		}
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(Object.assign({}, values, { recaptcha: this.state.recaptcha })),
		};
		await fetch(`${process.env.GATSBY_ZMS_API_URL}contact/mail/`, options)
			.then(async function (response) {
				if (!response.ok) {
					const body = await response.json();
					throw new Error(body.errorMessage);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					success: true,
				});
				if (this.timerID) clearTimeout(this.timerID);
				this.timerID = setTimeout(() => {
					this.timerID = null;
				}, 3000);

			})
			.catch(function (err) {
				alert(err);
			})
			.finally(function () {
				setSubmitting(false);
				document.getElementById("assistanceRequestForm").reset();
				resetForm({});
			})
			;
		this.recaptchaRef.current.reset();
	}

	render() {
		const employeeAreaOptions = [
			{ label: 'Field', value: 'field' },
			{ label: 'Staff', value: 'staff' }
		];
		const employeeArea2 = [
			{ label: 'Automation', value: 'automation' },
			{ label: 'Coatings', value: 'coatings' },
			{ label: 'Construction', value: 'construction' },
			{ label: 'Environmental', value: 'environmental' },
			{ label: 'Fabrication', value: 'fabrication' },
			{ label: 'Materials Management', value: 'materialsManagement' },
			{ label: 'Production Workforce', value: 'productionWorkforce' },
			{ label: 'Project Management', value: 'projectManagement' },
			{ label: 'Scaffolding', value: 'scaffolding' },
			{ label: 'Shorebase and Logistics', value: 'shorebaseLogistics' },
		];
		const employeeStandingOptions = [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
			{ label: 'Don&rsquo;t Know', value: 'usnure' },
		];
		const employeeValuesOptions = [
			{ label: 'Yes, in an exemplary way (employee is a leader in his/her area, attends safety focus, uses SWA, etc.)', value: 'yesExemplary' },
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
			{ label: 'Don&rsquo;t Know', value: 'unsure' },
		];
		const employeeMedicalOptions = [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
			{ label: 'N/A', value: 'usnure' },
		];
		const pageCopy = 'An employee-driven initiative, Danos Cares was created and funded by Danos employees through voluntary donations. Funds are distributed to employees who have needs arising from unexpected events.'
		const pullQuote = 'Danos’ purpose is to: Honor God. Develop great people to solve big challenges for our customers and communities.';
		return (
			<>
				<Layout image="/userfiles/Foundation Forms/foundation header v3.jpg" title="Danos CARES" enableDanosFoundation={false} enableFooterTabs={true}>
					<Intro headerTextCase="normal" headline={(this.page) ? this.page.headline : 'Assistance Request Form'} />
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								<section className="main-content">
									<div>
										{parse((this.page ? this.page.content.main : ''))}
									</div>
								</section>

								<section>
									<Formik
										initialValues={this.initialValues}
										validationSchema={this.formSchema}
										onSubmit={this.submitForm}
									>
										{({ values, isSubmitting }) => (
											<Form id="assistanceRequestForm">
												<div className={`${this.state.success ? style.hide : 'showMe'}`}>

													<div className="grid-x grid-margin-x">
														<div className="cell medium-6">
															<Field value={values.yourName} name="yourName" label="Your Name *" component={TextInput} />
														</div>
														<div className="cell medium-6">
															<Field value={values.employeeName} name="employeeName" label="Employee's Name *" component={TextInput} />
														</div>
													</div>

													<div className="grid-x grid-margin-x">
														<div className="cell medium-6">
															<Field value={values.email} name="email" label="Email Address *" component={TextInput} />
														</div>
														<div className="cell medium-6">
															<DatePicker value={values.todaysDate} name="todaysDate" label="Today's Date" />
														</div>
													</div>

													<Field value={values.employeeArea} component={RadioButton} horizontal={true} label="Employee's work area" name="employeeArea" options={employeeAreaOptions} />

													<Field value={values.employeeArea2} name="employeeArea2" label="Employee's service line" component={TextInput} />

													<Field value={values.employeeYears} name="employeeYears" label="How many years has the employee worked for Danos?" component={NumberInput} />

													<Field value={values.employeeStanding} component={RadioButton} horizontal={true} label="Is the employee in good standing with the company?" name="employeeStanding" options={employeeStandingOptions} />

													<Field value={values.employeeValues} component={RadioButton} label="Does the emoloyee represent Danos values of safety, service, respect, integrity and improvement?" name="employeeValues" options={employeeValuesOptions} />

													<Field value={values.message} name="message" label="Please share a brief description of the unexpected event (tell us what is going on)" lines="5" component={TextInput} />

													<Field value={values.employeeMedical} component={RadioButton} horizontal={true} label="If the unexpected event is medical in nature, does the employee have medical insurance?" name="employeeMedical" options={employeeMedicalOptions} />

													<p>Type of assistance requested (what is the need)</p>

													<div>
														<Field value={values.note} checked={values.note} type="checkbox" name="note" />
														<label htmlFor="note" className="form-check-label"><strong>Personal note signed by owners</strong> (thoughtful gesture to let the employee know that Danos is thinking of and praying for them during this difficult time)</label>
													</div>

													<div>
														<Field value={values.flowers} checked={values.flowers} type="checkbox" name="flowers" />
														<label htmlFor="flowers" className="form-check-label"><strong>Flowers</strong> (good option for someone recovering from surgery and a cheerful reminder that they are being thought of)</label>
													</div>

													<div>
														<Field value={values.dinner} checked={values.dinner} type="checkbox" name="dinner" />
														<label htmlFor="dinner" className="form-check-label"><strong>Dinner</strong> (comforting option for someone recovering from surgery or overwhelmed with daily tasks)</label>
													</div>

													<div>
														<Field value={values.blanket} checked={values.blanket} type="checkbox" name="blanket" />
														<label htmlFor="blanket" className="form-check-label"><strong>Danos branded plush blanket</strong> (nice option for those undergoing chemo or other medical treatments and a physical reminder that his/her coworkers are thinking of them)</label>
													</div>

													<div>
														<Field value={values.ppe} checked={values.ppe} type="checkbox" name="ppe" />
														<label htmlFor="ppe" className="form-check-label"><strong>PPE</strong> (good choice for those who have lost work clothes and are ready to get back to work)</label>
													</div>

													<div>
														<Field value={values.monetary} checked={values.monetary} type="checkbox" name="monetary" />
														<label htmlFor="monetary" className="form-check-label"><strong>Monetary donation</strong> (if money is requested, please state what the financial need is and if donations are expected from other sources)</label>
														<Field value={values.monetaryRequest} name="monetaryRequest" label="" hideLabel={true} lines={5} component={TextInput} />
													</div>

													<div>
														<Field value={values.otherCheck} checked={values.otherCheck} type="checkbox" name="otherCheck" />
														<label htmlFor="otherCheck" className="form-check-label"><strong>Other</strong></label>
														<Field value={values.otherRequest} name="otherRequest" label="" hideLabel={true} component={TextInput} />
													</div>

													<DatePicker value={values.requestedDate} name="requestedDate" label="Date requested by" />

													<p><em>Assistance is allocated based on availability, need and approval.</em></p>

													<div style={{ marginTop: '1rem', marginBottom: '1rem', position: 'relative' }}><div style={{ zIndex: 2, position: 'relative' }}><ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={this.recaptchaComplete} ref={this.recaptchaRef} /></div><div className={[style.error, (this.state.recaptchaError ? style.active : '')].join(' ')}>You must complete the ReCAPTCHA</div></div>

													<button className="button primary" type="submit" disabled={isSubmitting}>Submit</button>

												</div>

												{<div className={[style.success, (this.state.success ? style.showSuccess : '')].join(' ')}>Thank you for contacting us. Someone will be in touch with you soon.</div>}
											</Form>
										)}
									</Formik>
								</section>
							</div>
						</div>
					</div>
				</Layout>
				<SEO title={this.page ? this.page.pageTitle : 'Assistance Request Form'} meta={this.page ? this.page.meta : ''} />
			</>
		)
	}
}

export const query = graphql`
	query ($uri: String, $lang: String) {
	  allContentPage(filter: {uri: {eq: $uri}, lang: {eq: $lang}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			link
			linkText
			danosFoundation
			danosFooterTab
	      }
	    }
	  }
	}
`